import type { FC } from "react";
import React, { forwardRef, useEffect, useState } from "react";
import { BiChevronRight, BiRightArrow } from "react-icons/bi";
import { Link } from "react-router-dom";

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import type {
  BoxProps,
  SkeletonProps,
  StackProps,
  StyleProps,
  WrapProps,
} from "@chakra-ui/react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";

import { useGlobalContext } from "~/context/global";
import {
  useLocale,
  useLocaleNavigate,
  useRegion,
  useTranslation,
} from "~/libs/i18n";

export const LinkWrap: FC<
  StyleProps & {
    children: React.ReactNode;
    to: string;
    onClick?: (data?: any) => void;
  }
> = ({ children, to, onClick }) => {
  return (
    <Link to={to ?? "/"} onClick={onClick}>
      {children}
    </Link>
  );
};

export const SkeletonCard = (props: SkeletonProps & { item?: Number }) => {
  let skeletonArray = new Array(props?.item ?? 3);
  return (
    <Stack w="full" {...props}>
      {skeletonArray.map((_, index) => (
        <Skeleton height={5} key={index} />
      ))}
    </Stack>
  );
};

export const Section: FC<
  StyleProps & {
    children: React.ReactNode;
  }
> = ({ children, ...flexProps }) => {
  return (
    <Flex w="full" p={2} {...flexProps}>
      <Container
        px={0}
        maxW={[
          "full",
          "container.sm",
          "container.md",
          "container.lg",
          "container.xl",
          "container.2xl",
        ]}
      >
        {children}
      </Container>
    </Flex>
  );
};

export const Breadcrumb: FC<
  StyleProps & {
    items: { label: string; url: string }[];
  }
> = ({ items = [], ...styleProps }, props: WrapProps) => {
  const navigate = useLocaleNavigate();
  const { isDesktop } = useGlobalContext();
  const [isHydrated, setIsHydrated] = useState(false);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  if (!isDesktop || !isHydrated) return null;

  return (
    <Wrap pt={4} {...props} {...styleProps}>
      {items?.map(({ label, url }, index) => {
        const lastItem = index + 1 === items.length;
        const item = (
          <Box
            as="button"
            key={`item-${index}`}
            color={!lastItem ? "brand.link-blue" : ""}
            fontWeight="normal"
            onClick={() => navigate(url)}
          >
            {label}
          </Box>
        );
        const arrow = (
          <Icon key={`arrow-${index}`} fontSize={"2xl"} as={BiChevronRight} />
        );
        if (index > 0) {
          return [arrow, item];
        }
        return item;
      })}
    </Wrap>
  );
};

export const PageHeader: FC<
  StyleProps & {
    title: string;
  }
> = ({ title, ...styleProps }) => {
  return (
    <Heading
      as="h5"
      py={[2, 4, 6]}
      fontSize={["lg", "xl", "2xl"]}
      {...styleProps}
    >
      {title}
    </Heading>
  );
};

export const TitleRow: FC<{
  title: string;
  text?: any;
  url?: any;
}> = ({ title, url, text }) => {
  const t = useTranslation();
  const navigate = useLocaleNavigate();
  return (
    <Flex w="100%" justifyContent={"space-between"} mb={6}>
      <Heading fontSize={["2xl"]} flex={1} as="h6">
        {title}
      </Heading>
      {url && (
        <Button
          size="sm"
          rightIcon={<Icon as={BiRightArrow} />}
          variant="link"
          onClick={() => navigate(url)}
        >
          {text ?? t("all")}
        </Button>
      )}
    </Flex>
  );
};

export const ListWrapper: FC<BoxProps & StyleProps> = ({
  children,
  ...styleProps
}) => {
  return (
    <Box px={{ base: 2, xl: 0 }} {...styleProps}>
      <Section>{children}</Section>
    </Box>
  );
};

export const SidebarWrapper: FC<BoxProps & StyleProps> = ({
  children,
  ...styleProps
}) => {
  return (
    <Box
      maxW={{ base: "100%", lg: 300 }}
      w={"100%"}
      display={["none", "none", "none", "block"]}
      overflow="hidden"
      {...styleProps}
    >
      <Flex gap={6} direction={"column"} w={"100%"}>
        {children}
      </Flex>
    </Box>
  );
};

export const CardWrapper: FC<
  StackProps & StyleProps & { to?: string; onClick?: (data?: any) => void }
> = ({ children, to, onClick, ...styleProps }) => {
  const locale = useLocale();
  const region = useRegion();
  return (
    <LinkWrap to={`/${region}/${locale}${to}` ?? "#"} onClick={onClick}>
      <VStack
        overflow="hidden"
        rounded="xl"
        boxShadow="md"
        cursor={"pointer"}
        alignItems="stretch"
        position={"relative"}
        _hover={{
          bg: "gray.50",
          boxShadow: "sm",
          cursor: "pointer",
          transitionProperty: "all",
          transitionDuration: "0.3s",
        }}
        flexShrink={{ base: 0, lg: 1 }}
        {...styleProps}
      >
        {children}
      </VStack>
    </LinkWrap>
  );
};

export const BoxWrapper: FC<StackProps & StyleProps> = ({
  children,
  ...styleProps
}) => {
  return (
    <Box
      overflow="hidden"
      boxShadow="sm"
      bg="white"
      borderWidth={1}
      borderColor={"gray.200"}
      borderRadius="md"
      // key={event.id}
      _hover={{
        bg: "gray.50",
        boxShadow: "xl",
        cursor: "pointer",
        transitionProperty: "all",
        transitionDuration: "0.3s",
      }}
      {...styleProps}
    >
      {children}
    </Box>
  );
};

interface Props extends BoxProps {
  children: React.ReactNode;
  noOfLines: number;
  wrapperHeight?: number;
}

export const ExpandableText = forwardRef<HTMLDivElement, Props>(
  ({ children, noOfLines, wrapperHeight, ...rest }, ref) => {
    const t = useTranslation();
    const [expandedCount, setExpandedCount] = useState<number | undefined>(
      noOfLines
    );
    const [isClicked, setIsClicked] = useState(false);
    const handleToggle = () => {
      setIsClicked(true);
      setExpandedCount(expandedCount ? undefined : noOfLines);
    };

    const inputRef = React.useRef<HTMLInputElement>(null);

    const refHeight =
      wrapperHeight ?? (inputRef.current?.scrollHeight as number);

    const isTextClamped =
      refHeight > (inputRef.current?.clientHeight as number) || isClicked;

    return (
      <Box ref={ref} {...rest}>
        <Box ref={inputRef} noOfLines={expandedCount}>
          {children}
        </Box>
        <Flex justifyContent={"flex-end"} my={1}>
          <Button
            display={isTextClamped ? "flex" : "none"}
            size="sm"
            variant="link"
            onClick={handleToggle}
            alignSelf={"flex-end"}
          >
            <Text color="brand.link-blue">
              {!expandedCount ? t("member.showLess") : t("member.showMore")}
            </Text>
            {isClicked ? (
              <ChevronUpIcon color="brand.link-blue" />
            ) : (
              <ChevronDownIcon color="brand.link-blue" />
            )}
          </Button>
        </Flex>
      </Box>
    );
  }
);
ExpandableText.displayName = "ExpandableText";

// const SortFilter = () => {
//   const t = useTranslation();

//   const [searchParams, setSearchParams] = useSearchParams();

//   let value = searchParams.get("sort");
//   if (!value) value = "sort";

//   const onChange: ChangeEventHandler<HTMLSelectElement> = (e) =>
//     setSearchParams(
//       (sp) => {
//         sp.set("sort", e.target.value);
//         sp.set("page", "1");
//         return sp;
//       },
//       { state: { scroll: false } }
//     );

//   return (
//     <Select
//       value={value}
//       onChange={onChange}
//       focusBorderColor="orange.300"
//       borderRadius="md"
//       w={32}
//       size="sm"
//       variant="outline"
//     >
//       <option value={"views"}>{t("filters.hits")}</option>
//       <option value={"latest"}>{t("filters.latest")}</option>
//     </Select>
//   );
// };
